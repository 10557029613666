<template>
    <div class="period-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">学年度管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="学年度" prop="name">
                <el-input v-model="queryModel.name" size="mini"/>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
            
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
            <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd(null)" >新增</el-button>
            <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-remove"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
        >删除选中项</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="name" label="学年度" fixed="left"></el-table-column>
            <el-table-column prop="startTime" label="服务期限" fixed="left">
                <template slot-scope="{row}">
                    <span>{{row.startTime}}&nbsp;至&nbsp;{{row.endTime}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="active" label="激活状态" fixed="left">
                <template slot-scope="{row}">
                    <span v-if="row.active">已激活</span>
                    <span v-else>未激活</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" fixed="left"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <vehiclePeriod-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></vehiclePeriod-detail>
    </div>
</template>
<script>
import vehiclePeriodApi from '@/api/bus/vehiclePeriod'
import vehiclePeriodDetail from "./vehiclePeriod-detail";
export default {
    name:"busVehiclePeriodList",
    data(){
        return{
            queryModel: {
                name: ''
            },
            loading: false,
            tableData: [],
            statusData:[],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
        }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("name", this.queryModel.name);


            vehiclePeriodApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleAdd(record) {
            this.modalTitle = "新增";
            this.operation = "add";
            this.businessKey = "";

            if (record != null) {
                this.selectedRecord = record;
            } else {
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        handleBatchDelete() {
            var self = this;

            var idList = this.multipleSelection.map(record => {
                return record.id;
            });

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                vehiclePeriodApi.batchRemove(idList).then(function(response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                    type: "success",
                    message: "删除成功!"
                    });
                }
                });
            });
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    list.splice(i, 1);
                    rs = true;
                    break;
                    }

                    if (list[i].children != null) {
                    rs = this.loopDelete(list[i].children, id);

                    if (rs) {
                        break;
                    }
                }
            }

            return rs;
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                    vehiclePeriodApi.remove(record.id).then(function(response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                        var rs = self.loopDelete(self.tableData,record.id);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                if (this.operation == "edit") {
                    this.selectedRecord.name = retData.name;

                    this.changePage(this.pageIndex);

                } else if (this.operation == "add") {
                    if (this.selectedRecord.id == null) {
                        console.log("tableData.push");
                        this.tableData.push(retData);
                    } else {
                        if (this.selectedRecord.children == null) {
                        this.selectedRecord.children = [];
                        }

                        this.selectedRecord.hasChildren = true;
                        this.selectedRecord.children.push(retData);
                    }
                }
            }
        }
    },
    mounted() {
        this.changePage(1);
    },
    components: {
        "vehiclePeriod-detail": vehiclePeriodDetail
    },
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.period-list{
    text-align: left;
}

</style>