import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/vehiclePeriod/pageList", formData);
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/vehiclePeriod/edit/" + id);
}

function create(){
  return request.get(constant.serverUrl + "/bus/vehiclePeriod/create");
}

function add(formModel){
    return request.post(constant.serverUrl + "/bus/vehiclePeriod/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}

function update(formModel){  
    return request.post(constant.serverUrl + "/bus/vehiclePeriod/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function remove(id){
  return request.post(constant.serverUrl + "/bus/vehiclePeriod/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/bus/vehiclePeriod/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
    pageList,edit,create,add,update,remove,batchRemove
}