<template>
    <div>
        <el-dialog
        width="30%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate">
                    <el-form-item label="学年度" prop="name" label-width="80px">
                        <el-input v-model="formModel.name" placeholder="请输入学年度" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期限" prop="dateRanges">
                        <template>
                            <div class="block">
                                <el-date-picker
                                style="width:300px"
                                v-model="formModel.dateRanges"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                ></el-date-picker>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="激活状态" prop="active" label-width="80px">
                        <el-switch
                            v-model="formModel.active">
                        </el-switch>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Constant from "@/constant";
import vehiclePeriodApi from '@/api/bus/vehiclePeriod'
export default {
    props: ["businessKey", "title"],
    data(){
        return{
            ruleValidate: {
                name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
                dateRanges: [{ required: true, message: "服务期限不能为空", trigger: "blur" }]
            },
            showDialog: true,
            loading: false,
            formModel: {
                id: "",
                name: "",
                dateRanges:"",
                active:true
            },
            submitting: false,
        }
    },
    methods:{
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;
                    var dateRanges = self.formModel.dateRanges;
                    self.formModel.startTime = dateRanges[0];
                    self.formModel.endTime = dateRanges[1];
                    self.formModel.dateRanges=dateRanges.toString();
                    if (id == null || id.length == 0) {
                        return vehiclePeriodApi.add(self.formModel);
                    } else {
                        return vehiclePeriodApi.update(self.formModel);
                    }
                })().then(function(response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                        self.$message({
                            message: "保存成功!",
                            type: "success"
                        });
                        self.$emit("close", {
                            result: true,
                            data: jsonData.data
                        });
                    } else {
                        self.$message({
                            message: jsonData.message + "",
                            type: "warning"
                        });

                        self.$emit("close", {
                            result: false
                        });
                    }
                });
                }
            });
        },
    },
  async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
        if (self.businessKey.length == 0) {
            return vehiclePeriodApi.create();
        } else {
            return vehiclePeriodApi.edit(self.businessKey);
        }
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel = jsonData.data;
                if(self.formModel.id!=null){
                    let time1 = new Date(jsonData.data.startTime);
                    let time2 = new Date(jsonData.data.endTime);
                    self.formModel.dateRanges=[time1,time2]
                }
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>

</style>